/*
 * @Author: CHY
 * @Date: 2023-07-11 15:45:19
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-07-14 10:06:19
 * @FilePath: \food-trading\src\assets\js\utils\auth.js
 */
let Base64 = require('js-base64').Base64

const setToken = (token) => {
    localStorage.setItem('accessToken', token)
}

const getToken = () => {
    return localStorage.getItem('accessToken') || ''
}

const removeToken = () => {
    return localStorage.removeItem('accessToken')
}


const setUserInfo = (userInfo) => {
    if (typeof userInfo !== 'string') {
        userInfo = JSON.stringify(userInfo)
    } 
    localStorage.setItem('userInfo', Base64.encode(userInfo))
}

const getUserInfo = () => {
    const info = localStorage.getItem('userInfo') || ''
    if (info) {
        return JSON.parse(Base64.decode(info))
    }
    return {}

}

const removeUserInfo = () => {
    return localStorage.removeItem('userInfo')
}

export {
    setToken,
    getToken,
    removeToken,

    setUserInfo,
    getUserInfo,
    removeUserInfo
}