/*
 * @Author: CHY
 * @Date: 2023-07-04 09:19:22
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-25 15:32:39
 * @FilePath: \food-trading\src\router\index.js
 */
/*
 * @Author: CHY
 * @Date: 2023-07-04 09:19:22
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-08-08 16:42:19
 * @FilePath: \food-trading\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/layout/Index'
/**
 * path：路由的访问路径
 * name：路由的访问名称
 * component：路由对应的组件
 * meta：路由的额外配置
 * 
 * meta的配置参数说明：
 *      title：页面的title
 *      activeMenu：聚焦路由路径
 *      breadcrumb：是否启用面包屑，默认为false  
 *      keepAlive：是否启页面缓存，默认为false 
 *      channelFlag: 横额广告的类型，如果不传入，则不展示横额广告，0:网站首页、1:在线商城、2:现场展示、3:基地展示、4:检测公示、5:服务支持、6:联系我们、7:实施案例、8:政策法规、9:扫码溯源
 */
Vue.use(Router)
const router = new Router({
    routes: [{
        path: '/',
        name: 'index',
        meta: {
            title: '廉餐配送',
            channelFlag: 0,
            keepAlive: true
        },
        component: () => import("@/views/IndexNew")
    }, {
        path: '/online-shop',
        component: layout,
        meta: {
            title: '在线商城',
            channelFlag: 1
        },
        children: [{
            path: '',
            name: 'OnlineShop',
            meta: {
                title: '在线商城',
                channelFlag: 1
            },
            component: () => import("@/views/onlineShop/Index"),
        }, {
            path: 'detail',
            name: 'OnlineShopDetail',
            meta: {
                title: '商品详情',
                activeMenu: '/online-shop',
                breadcrumb: true,
                channelFlag: 1
            },
            component: () => import("@/views/onlineShop/Detail")
        }, {
            path: 'shopping-car',
            name: 'ShoppingCar',
            meta: {
                title: '购物车',
                activeMenu: '/online-shop',
                breadcrumb: true,
                channelFlag: 1
            },
            component: () => import("@/views/onlineShop/ShoppingCar")
        }]
    }, {
        path: '/live-display',
        component: layout,
        meta: {
            title: '现场展示'
        },
        children: [{
            path: '',
            name: 'LiveDisplay',
            meta: {
                title: '首页-现场展示',
                channelFlag: 2
            },
            component: () => import("@/views/liveDisplay/Index")
        }, {
            path: 'detail',
            name: 'LiveDisplayDetail',
            meta: {
                title: '详情-现场展示',
                activeMenu: '/live-display',
                breadcrumb: true,
                channelFlag: 2
            },
            component: () => import("@/views/liveDisplay/Detail")
        }]
    }, {
        //     path: '/base-display',
        //     name: 'BaseDisplay',
        //     meta: {
        //         title: '基地展示',
        //         channelFlag: 3
        //     },
        //     component: () => import("@/views/BaseDisplay")
        // }, {
        path: '/implementation-cases',
        component: layout,
        meta: {
            title: '实施案例',
            channelFlag: 7
        },
        children: [{
            path: '',
            name: 'ImplementationCases',
            meta: {
                title: '首页-实施案例',
                channelFlag: 7
            },
            component: () => import("@/views/implementationCases/Index")
        }, {
            path: 'detail',
            name: 'ImplementationCasesDetail',
            meta: {
                title: '详情-实施案例',
                activeMenu: '/implementation-cases',
                breadcrumb: true,
                channelFlag: 7
            },
            component: () => import("@/views/implementationCases/Detail")
        }]
    }, {
        path: '/scan-code-traceability',
        component: layout,
        meta: {
            title: '扫码溯源'
        },
        children: [{
            path: '',
            name: 'ScanCodeTraceability',
            meta: {
                title: '首页-扫码溯源',
                channelFlag: 9
            },
            component: () => import("@/views/scanCodeTraceability/Index")
        }]
    }, {
        path: '/inspection-publicity',
        name: 'InspectionPublicity',
        meta: {
            title: '食品检测',
            channelFlag: 4
        },
        component: () => import("@/views/InspectionPublicity/Index")
    }, {
        path: '/statute',
        component: layout,
        meta: {
            title: '政策法规'
        },
        children: [{
            path: '',
            name: 'Statute',
            meta: {
                title: '首页-政策法规',
                channelFlag: 8,
                keepAlive: true
            },
            component: () => import("@/views/statute/Index")
        }, {
            path: 'detail',
            name: 'StatuteDetail',
            meta: {
                title: '详情-政策法规',
                activeMenu: '/statute',
                breadcrumb: true,
                channelFlag: 8
            },
            component: () => import("@/views/statute/Detail")
        }]
    }, {
        path: '/service-support',
        component: layout,
        meta: {
            title: '服务中心'
        },
        children: [{
            path: '',
            name: 'ServiceSupport',
            meta: {
                title: '首页-服务中心',
                channelFlag: 5,
                keepAlive: true
            },
            component: () => import("@/views/ServiceSupport/Index")
        }, {
            path: 'detail',
            name: 'ServiceSupportDetail',
            meta: {
                title: '详情-服务中心',
                activeMenu: '/service-support',
                breadcrumb: true,
                channelFlag: 5
            },
            component: () => import("@/views/ServiceSupport/Detail")
        }]
    }, {
        path: '/contact-us',
        name: 'ContactUs',
        meta: {
            title: '联系我们',
            channelFlag: 6,
            keepAlive: true
        },
        component: () => import("@/views/ContactUs")
    }, {
        path: '*',
        name: '404',
        meta: {
            title: '404'
        },
        component: () => import("@/views/404")
    }]
})


// 获取原型对象push函数
const originalPush = Router.prototype.push

// 获取原型对象replace函数
const originalReplace = Router.prototype.replace

// 修改原型对象中的push函数
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}


// 前置路由守卫router.beforeEach()：在初始化和每一次路由切换之前调用函数
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    // console.log('beforeEach', to.matched, from)
    // window.scrollTo(0, 0) // 滚动到顶部
    next()
})
// 和 router.beforeEach 类似，因为它在 每次导航时都会触发，但是确保在导航被确认之前，同时在所有组件内守卫和异步路由组件被解析之后，解析守卫就被正确调用。
// 是获取数据或执行任何其他操作（如果用户无法进入页面时你希望避免执行的操作）的理想位置。
// eslint-disable-next-line no-unused-vars
router.beforeResolve(async (to, from, next) => {
    // console.log('beforeResolve', to, from)
    next()
})
// 全局后置钩子router.afterEach()：在离开路由的时候执行。
// 和守卫不同的是，这些钩子不会接受 next 函数也不会改变导航本身，它们对于分析、更改页面标题、声明页面等辅助功能以及许多其他事情都很有用
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    // console.log('afterEach', to, from)
    if (to.meta.title) {
        document.title = to.meta.title //修改网页的title
    } else {
        document.title = "食材交易网"
    }
})

export default router