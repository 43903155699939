/*
 * @Author: CHY
 * @Date: 2023-07-11 15:28:20
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-07-29 09:39:51
 * @FilePath: \food-trading\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

import {
    getToken,
    setUserInfo,
    getUserInfo,
} from '@/assets/js/utils/auth'

Vue.use(Vuex)
const store = new Vuex.Store({
    //数据大管家，管理着公共的数据 
    state: {
        regionId: JSON.parse(localStorage.getItem("regionId") || '["440000", "440900", "440983"]'),
        userInfo: getUserInfo(),
        token: getToken()
    },
    //进行同步操作
    mutations: {
        changeRegionId(state, ids) {
            state.regionId = ids
            localStorage.setItem("regionId", JSON.stringify(state.regionId))
        },
        setUserInfo(state, info) {
            setUserInfo(info)
        }
    },
    //进行异步操作，例如发送网络请求
    actions: {

    },
    getters: {},
    modules: {}
})

export default store