<!--
 * @Author: CHY
 * @Date: 2023-07-04 09:14:27
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-08-09 17:26:26
 * @FilePath: \food-trading\src\App.vue
-->
<template>
    <div ref="app">
        <!-- <layout-header v-if="!isHome" /> -->
        <layout-nav />
        <layout-banner v-if="!isHome" />
        <layout-breadcrumb v-if="!isHome" />
        <div id="app">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" />
        </div>
        <layout-bottom />
        <layout-back-top />
    </div>
</template>

<script>
    // import layoutHeader from '@/layout/Header'
    import layoutNav from '@/layout/Nav'
    import layoutBreadcrumb from '@/layout/Breadcrumb'
    import layoutBanner from '@/layout/Banner'
    import layoutBottom from '@/layout/Bottom'
    import layoutBackTop from '@/layout/BackTop'
    import buddha from '@/assets/js/utils/buddha.js'

    import {
        setToken,
        setUserInfo
    } from '@/assets/js/utils/auth.js'

    export default {
        name: 'App',
        components: {
            // layoutHeader,
            layoutNav,
            layoutBanner,
            layoutBreadcrumb,
            layoutBottom,
            layoutBackTop
        },
        data() {
            return {
                isHome: false,
            }
        },
        watch: {
            '$route': {
                immediate: true,
                deep: true,
                handler(value) {
                    this.isHome = value.fullPath === '/' && !value.query.accessToken

                    if (value.query.accessToken) {
                        setToken(value.query.accessToken)
                        const userInfo = {
                            username: value.query.userName,
                            avatar: value.query.avatar,
                            account: value.query.account
                        }
                        setUserInfo(userInfo)
                        location.href = '/'
                    }
                }
            }
        },
        created() {
            if (process.env.NODE_ENV === "development") {
                console.log(buddha)
            }
        },
        mounted() {
            window.addEventListener("resize", this.bodyScale(), false)
            window.addEventListener('beforeunload', () => {
                localStorage.removeItem('currentCollapse')
            })
        },
        methods: {
            bodyScale() {
                var deviceWidth = document.documentElement.clientWidth //获取当前分辨率下的可是区域宽度
                var scale = deviceWidth / 1920 // 分母——设计稿的尺寸
                document.documentElement.style.zoom = scale //放大缩小相应倍数
            }
        }
    }
</script>

<style lang="scss">
    // @import '@/assets/css/font.scss';

    .region-cascader {

        .el-cascader-node.in-active-path,
        .el-cascader-node.is-active,
        .el-cascader-node.is-selectable.in-checked-path {
            color: #008F4D;
            @include adaptive(14px)
        }
    }

    .client {
        border: none !important;
        box-shadow: 0 0 10px rgba(0, 143, 77, .1);

        .login-parent {
            @include d-column-align-center;

            .el-button--text {
                font-size: 14px;
                color: #010101;
                font-weight: 400;
                margin-left: 0;

                &:hover {
                    color: #008F4D;
                }
            }
        }
    }

    .el-image-viewer__mask {
        opacity: .95 !important;
    }
</style>

<style>
    @import '@/assets/css/quill.css';


    * {
        margin: 0;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 47vh;
    }

    html {
        scroll-behavior: smooth;
    }

    /* :root {
        font-family: MiSans;
        font-weight: 400;
    } */

    /*css主要部分的样式*/
    /*定义滚动条宽高及背景，宽高分别对应横竖滚动条的尺寸*/
    ::-webkit-scrollbar {
        width: 0;
        /*对垂直流动条有效*/
        height: 6px;
        /*对水平流动条有效*/
    }

    /*定义滚动条的轨道颜色、内阴影及圆角*/
    ::-webkit-scrollbar-track {
        border-radius: 4px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
        background-color: rgb(128, 128, 128, .1);
    }

    /*定义滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgba(0, 143, 77, .8);
        /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
    }

    /*定义滑块悬停变化颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb:hover {
        background-color: #c7c9cc;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        /* background-color: cyan; */
        height: 0;
    }

    /*定义右下角汇合处的样式*/
    /* ::-webkit-scrollbar-corner {
        background: khaki;
    } */
</style>