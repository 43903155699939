/*
 * @Author: CHY
 * @Date: 2023-07-26 16:53:56
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-24 18:29:59
 * @FilePath: \food-trading\src\assets\js\data\nav.js
 */
const nav = [{
    label: '网站首页',
    linkTo: '/'
}, {
    label: '在线商城',
    linkTo: '/online-shop'
}, {
    label: '现场展示',
    linkTo: '/live-display'
    // }, {
    //     label: '基地展示',
    //     linkTo: '/base-display'
}, {
    label: '实施案例',
    linkTo: '/implementation-cases'
}, {
    label: '扫码溯源',
    linkTo: '/scan-code-traceability'
}, {
    label: '食品检测',
    linkTo: '/inspection-publicity'
}, {
    label: '政策法规',
    linkTo: '/statute'
}, {
    label: '服务中心',
    linkTo: '/service-support'
}, {
    label: '联系我们',
    linkTo: '/contact-us'
}]

export {
    nav
}