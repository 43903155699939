const tencentLocation = {
    // baseUrl: '/ws/location/v1/ip',
    // key: '3WCBZ-4TCKG-4F5QC-QWBZY-DGEK5-EFFE2',
    // secret: 'GFYspj18Rwj5rBJjvWXdz2yJyzQsYNIg'
    baseUrl: '/ws/location/v1/ip',
    key: 'HX5BZ-BURC4-M5UUL-XS5UV-FINL2-ZSFHH',
    secret: '6XXRLxf7MI8iYJTQciJ3UT0mzgvGMnan'
}

export {
    tencentLocation
}