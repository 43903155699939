<!--
 * @Author: CHY
 * @Date: 2023-07-05 10:45:13
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-20 14:28:18
 * @FilePath: \food-trading\src\layout\Nav.vue
-->
<template>
    <div class="nav-bg">
        <div class="nav-parent">
            <div class="left-view" @click="toIndex">
                <img src="images/logo.png" class="logo" />
                <div class="text-parent">
                    <!-- <div class="title">中小学校幼儿园廉餐食堂 | <span>政府统一配送</span></div> -->
                    <div class="title">廉餐产业(生态链) | <span>政府统一配送</span></div>
                    <div class="sub-title">让每一分钱都用在学生身上</div>
                </div>
            </div>
            <div class="right-view">
                <div v-for="(item, index) in navs" :key="index" class="nav-item" :class="currentNav===index?'active':''" @click="linkTo(item)">{{ item.label }}</div>
                <el-tooltip v-if="!token" class="item" effect="light" content="Bottom Center 提示文字" placement="bottom" popper-class="client">
                    <div slot="content" class="login-parent">
                        <el-button type="text" @click.stop="toCanteen">学校食堂</el-button>
                        <el-button type="text" @click.stop="toDistributionCentre">配送中心</el-button>
                        <!-- <el-button type="text" @click.stop="toPreparedDish">预制菜</el-button> -->
                    </div>
                    <el-button class="btn-login"> 登录</el-button>
                </el-tooltip>
                <div v-if="token" class="info">
                    <el-dropdown>
                        <span class="name">
                            欢迎你,<img :src="userInfo.avatar" alt="">{{ userInfo.username }}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div class="btn-text canteen" @click.stop="toCanteenIndex">进入食堂后台</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div class="btn-text" @click.stop="toLogout">退出登录</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- 位置 -->
                <div class="region">
                    <img src="images/icon_location_yellow.png" class="location">
                    <el-cascader v-model="localRegionId" :props="regionProps" :show-all-levels="false" popper-class="region-cascader" @change="changeRegion"></el-cascader>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'
    import {
        nav
    } from '@/assets/js/data/nav.js'
    import {
        removeToken,
        removeUserInfo
    } from '@/assets/js/utils/auth'
    import md5 from 'js-md5'
    import {
        tencentLocation
    } from '@/assets/js/data/config'
    import {
        jsonp
    } from 'vue-jsonp'
    import regionData from '@/assets/js/data/region'


    export default {
        data() {
            return {
                localRegionId: new Array(3).fill(''),
                currentNav: '',
                navs: nav,
            }
        },
        watch: {
            $route: {
                deep: true,
                immediate: true,
                handler(value) {
                    if (value.meta && value.meta.activeMenu) {
                        const index = this.navs.findIndex(item => {
                            return item.linkTo === value.meta.activeMenu
                        })
                        this.currentNav = index === -1 ? '' : index
                    } else if (value.fullPath) {
                        const index = this.navs.findIndex(item => {
                            return value.fullPath.replace(/\/\?.*/, '') === item.linkTo
                        })
                        this.currentNav = index === -1 ? '' : index
                    }
                }
            },
            regionId: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.localRegionId = value
                }
            }
        },
        computed: {
            ...mapState(['regionId', 'userInfo', 'token']),
            regionProps() {
                return {
                    lazy: true,
                    lazyLoad: async (node, resolve) => {
                        const {
                            level
                        } = node
                        let info = []
                        switch (level) {
                            case 0: // 获取省
                                info = await this.$http.get(this.$api.getProvince)
                                break
                            case 1: // 获取市
                                info = await this.$http.get(this.$api.getCity + node.value)
                                break
                            case 2: // 获取区
                                info = await this.$http.get(this.$api.getArea + node.value)
                                break
                            default:
                                break
                        }
                        if (info.data.length > 0) {
                            info = info.data.map(item => {
                                return {
                                    value: item.id,
                                    label: item.regionName,
                                    leaf: level >= 2
                                }
                            })
                        } else if (node.data && info.data.length === 0) {
                            node.data.leaf = true
                        }
                        resolve(info)
                    }
                }
            }
        },
        created() {
            this.getLocation()
        },
        methods: {
            ...mapMutations(['changeRegionId']),
            // 获取定位
            getLocation() {
                const uri = `${tencentLocation.baseUrl}?callback=jsonpCallback&key=${tencentLocation.key}&output=jsonp`
                const sign = md5(uri + tencentLocation.secret)
                jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
                    //腾讯的api上有个callback参数，这是使用指定回调函数
                    //使用vue-jsonp时这个参数需要通过callbackQuery和callbackName来设置
                    //否vue-jsonp会随机生成一个callback参数这样无论怎么弄都会报签名校验失败
                    callbackQuery: 'callback', // 设置callback参数的key  不设置的话callback参数会自动被赋予一个随机值  md5校验无法通过
                    callbackName: 'jsonpCallback', // 设置callback 参数的值
                    //其他参数正常传
                    key: tencentLocation.key,
                    output: 'jsonp',
                    sig: sign
                }).then(res => {
                    const {
                        ad_info: {
                            province,
                            city,
                            district
                        }
                    } = res.result
                    if (!province || !city || !district) {
                        this.$message({
                            type: 'warning',
                            message: '定位失败，请在右上角手动选择您所在的位置'
                        })
                        return
                    }
                    regionData.forEach(item => {
                        if (province && item.region_name.indexOf(province) !== -1 && item.id % 10000 === 0) {
                            this.localRegionId[0] = item.id.toString()
                        }
                        if (city && item.region_name.indexOf(city) !== -1) {
                            this.localRegionId[1] = item.id.toString()
                        }
                        if (district && item.region_name.indexOf(district) !== -1) {
                            this.localRegionId[2] = item.id.toString()
                        }
                    })
                    this.changeRegion()
                })
            },
            // 去食堂
            toCanteen() {
                window.open(process.env.VUE_APP_CANTEEN_LOGIN_URL.replace(/'/g, ''), '_self')
            },
            // 去配送中心
            toDistributionCentre() {
                window.open(process.env.VUE_APP_SUPPLIER_LOGIN_URL.replace(/'/g, ''), '_self')
            },
            // 去预制菜
            toPreparedDish() {
                window.open(process.env.VUE_APP_DISH_LOGIN_URL.replace(/'/g, ''), '_self')
            },
            toCanteenIndex() {
                window.open(process.env.VUE_APP_CANTEEN_INDEX_URL.replace(/'/g, ''), '_self')
            },
            toLogout() {
                removeToken()
                removeUserInfo()
                location.href = '/'
            },
            toIndex() {
                location.href = '/'
            },
            // 路由跳转
            linkTo(item) {
                this.$router.push({
                    path: item.linkTo
                })
            },
            // 改变地区
            changeRegion() {
                this.changeRegionId(this.localRegionId)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .nav-bg {
        background: linear-gradient(0deg, #008F4D, #1EC26F); 
        box-sizing: border-box;

        .nav-parent {
            margin: 0 auto;
            max-width: 1920px;
            width: 100%;
            z-index: 99;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // background: rgba($color: #000000, $alpha: 0.1);
            padding: 10px 70px;
            box-sizing: border-box;
            @include d-align-center;
            border-bottom: 2px solid rgba($color: #FFFFFF, $alpha: 0.5);

            .left-view {
                @include d-center;
                cursor: pointer;

                .logo {
                    width: 45px;
                    height: 67px;
                    margin-right: 10px;
                    object-fit: contain;
                }

                .text-parent {
                    @include d-column-align-start;

                    .title {
                        font-family: MiSans;
                        font-weight: 600;
                        color: #FFF;
                        font-size: 22px;

                        &>span {
                            color: #FFF
                        }
                    }

                    .sub-title {
                        font-weight: 400;
                        color: #FFF;
                        letter-spacing: 2px;
                        font-size: 15px;
                    }
                }
            }

            .right-view {
                max-width: 1080px;
                flex: 1;
                box-sizing: border-box;
                margin-left: 230px;
                @include d-justify-between;

                .nav-item {
                    font-size: 16px;
                    color: #FFFFFF;
                    font-weight: bold;
                    cursor: pointer;
                    padding: 5px 0;
                    border-top: 2px solid transparent;
                    border-bottom: 2px solid transparent;

                    &:hover,
                    &.active {
                        text-shadow: 2px 3px 1px rgba($color: #000000, $alpha: 0.3);
                        border-bottom: 2px solid #e6e6e6;
                    }
                }

                .btn-login {
                    background: rgb(255, 230, 0);
                    border-radius: 8px;
                    // color: #FFF;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 2px;

                    &:hover {
                        color: #008F4D;
                    }
                }


                .region {
                    @include d-align-center;
                    border: 1px solid #FFF;
                    padding: 5px 20px;
                    border-radius: 20px;
                    background: #FFF;

                    .location {
                        width: 13px;
                        height: 18px;
                        object-fit: contain;
                        filter: brightness(80%);
                    }

                    ::v-deep .el-cascader {
                        height: 18px;
                        line-height: 1;

                        input {
                            width: 4em;
                            height: 100%;
                            line-height: 1;
                            border: none;
                            background: transparent;
                            color: #666;
                            font-size: 16px;
                            font-weight: bold;
                            cursor: pointer;
                            padding: 0;
                            margin: 0;
                            margin-left: 15px;
                        }

                        .el-input__suffix {
                            display: none;
                        }
                    }
                }
            }

            .info {
                margin-left: 20px;

                .name {
                    @include d-align-center;
                    font-size: 16px;
                    font-weight: 600;
                    color: #FFF;
                }

                img {
                    width: 22px;
                    height: 22px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin: 0 10px;
                }
            }
        }
    }
</style>