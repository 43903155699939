<!--
 * @Author: CHY
 * @Date: 2023-07-05 10:50:20
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-07-13 18:45:23
 * @FilePath: \food-trading\src\layout\Bottom.vue
-->
<template>
    <div class="bottom-parent">
        <div class="bottom">
            <div class="title">友情链接</div>
            <div class="link-parent">
                <a :href="item.linkTo" target="_blank" v-for="(item, index) in friendlyLinks" :key="index" class="link-item"> {{ item.label }} </a>
            </div>
            <div class="copyright">
                技术支持:广州红新智能科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网站备案号: <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2020138104号</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                friendlyLinks: [
                    //     {
                    //     label: '廉餐食堂',
                    //     linkTo: 'https://www.liancanjd.com/#/'
                    // }, {
                    //     label: '餐检通',
                    //     linkTo: 'https://hx-food.mmdiandi.com/#/login'
                    // }
                    {
                        label: '广东省教育厅',
                        linkTo: 'http://edu.gd.gov.cn/'
                    }, {
                        label: '江苏省教育厅',
                        linkTo: 'http://jyt.jiangsu.gov.cn/'
                    }, {
                        label: '浙江省教育厅',
                        linkTo: 'http://jyt.zj.gov.cn/'
                    }, {
                        label: '山东省教育厅',
                        linkTo: 'http://edu.shandong.gov.cn/'
                    }, {
                        label: '江西省教育厅',
                        linkTo: 'http://jyt.jiangxi.gov.cn/'
                    }, {
                        label: '湖南省教育厅',
                        linkTo: 'http://jyt.hunan.gov.cn/'
                    }
                ]
            }
        },
        methods: {}
    }
</script> 
 
<style lang="scss" scoped>
    .bottom-parent {
        background: #2E2E2E;
        box-sizing: border-box;
        max-width: 1920px;
        margin: 0 auto;

        .bottom {
            width: 1200px;
            margin: 0 auto;
            padding: 43px 0 22px 0;

            .title {
                font-size: 31px;
                font-weight: 600;
                color: #FFFFFF;
            }

            .link-parent {
                box-sizing: border-box;
                margin-top: 28px;
                font-size: 14px;
                margin-top: 28px;
                display: grid;
                // grid-template-columns: 0.6fr 0.4fr 1fr 1fr 0.8fr 0.8fr 1fr 0.8fr;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                cursor: pointer;

                .link-item {
                    width: 100%;
                    text-align: center;
                    color: #FFFFFF;
                    font-weight: 500;
                    text-decoration: none;

                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }

            .copyright {
                width: 100%;
                border-top: 1px solid #525252;
                font-size: 14px;
                padding-top: 20px;
                margin-top: 60px;
                font-weight: 400;
                color: #b7b7b7FF;
                text-align: center;

                a {
                    color: inherit;
                }
            }
        }
    }
</style>