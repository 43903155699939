<!--
 * @Author: CHY
 * @Date: 2023-07-05 10:45:27
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-10-25 15:40:03
 * @FilePath: \food-trading\src\layout\Banner.vue
-->
<template>
    <div class="banner-parent" :style="{height:`${imgHeights[currentBannerIndex] || 0}px`}">
        <slider ref="slider" :options="options">
            <slideritem v-for="(item, index) in banners" :key="index">
                <img :ref="`img${index}`" :src="item.imgUrl" @load="calcImgHeight(index)" />
            </slideritem>
        </slider>
    </div>
</template>

<script>
    import {
        slider,
        slideritem
    } from 'vue-concise-slider'
    export default {
        components: {
            slider,
            slideritem
        },
        data() {
            return {
                banners: [],
                imgHeights: [],
                currentBannerIndex: 0
            }
        },
        watch: {
            $route: {
                deep: true,
                immediate: true,
                handler(value, oldValue) {
                    if (!value.meta && (value.meta.channelFlag === oldValue.meta.channelFlag)) {
                        return
                    }
                    if (value.meta.channelFlag >= 0) {
                        this.$nextTick(() => {
                            this.getBanner(value.meta.channelFlag)
                        })
                    } else {
                        this.imgHeights = []
                    }
                }
            }
        },
        computed: {
            options() {
                return {
                    effect: 'fade', //滚动滑动('slide'),可设置为渐变滑动('fade'),旋转滚动('coverflow')
                    currentPage: 0, //当前页码
                    thresholdDistance: 500, //滑动判定距离
                    thresholdTime: 500, //滑动判定时间
                    autoplay: 5000, //自动滚动[ms]
                    loop: true, //循环滚动
                    direction: 'horizontal', //方向设置，水平滚动
                    loopedSlides: 1, //无限滚动前后遍历数
                    slidesToScroll: 1, //每次滑动项数
                    timingFunction: 'ease', // 滑动方式
                    speed: 800, // 滑动时间
                    pagination: false, //所有模式下分页是否显示
                }
            },
        },
        methods: {
            // 获取通栏广告
            getBanner(channelFlag) {
                const options = {
                    params: {
                        channelFlag: channelFlag
                    }
                }
                this.$http.get(this.$api.getBanner, options).then(res => {
                    this.banners = res.data.records
                    if (this.banners.length === 0 && channelFlag !== 9) {
                        this.showBanner = false
                        this.imgHeights = []
                    }
                    // 扫码溯源
                    if (channelFlag === 9) { 
                        this.banners = [{
                            imgUrl: 'images/scan_code_traceability/banner2.png'
                        }]
                    }
                })
            },
            // 幻灯片改变的时候
            onChange(e) {
                this.currentBannerIndex = e
            },
            calcImgHeight(index) {
                console.log(index)
                if (this.imgHeights.length !== this.banners.length) {
                    this.imgHeights = new Array(this.banners.length)
                }
                this.$nextTick(() => {
                    this.$set(this.imgHeights, index, this.$refs[`img${index}`][0].offsetHeight)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .banner-parent {
        max-width: 1920px;
        margin: 0 auto;
        box-sizing: border-box;
        min-height: 90px;
        background: rgba($color: #000000, $alpha: .2);

        &:hover {
            img {
                // @include zoom(1, 1.1, "bannerIn", 0.3s)
            }
        }

        img {
            object-fit: contain;
            width: 100%;
            display: block;
            // @include zoom(1.1, 1, "bannerOut", 0.3s);
        }
    }
</style>