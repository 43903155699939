/*
 * @Author: CHY
 * @Date: 2023-07-05 16:28:02
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-08-14 11:37:29
 * @FilePath: \food-trading\src\assets\js\utils\request.js
 */

import axios from 'axios'
import {
    MessageBox,
    Message
} from 'element-ui'
import {
    getToken,
    removeToken,
    removeUserInfo
} from './auth'

// import store from '@/store'
// import {
//   getToken
// } from '@/utils/auth'

const service = axios.create({
    timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // if (store.getters.token) {
        //   config.headers['Authorization'] = getToken()
        // }
        config.headers['rabbit-token'] = getToken()
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        console.log(`访问路径：${response.config.url}`, JSON.parse(JSON.stringify(res)))
        if (res.code === 200) {
            return res
        }
        Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 5 * 1000
        })

        if (res.code === 401 || res.data.code === 401) {
            MessageBox.confirm('账号信息已过期，请重新登录', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                location.href = process.env.VUE_APP_CANTEEN_LOGIN_URL.replace(/'/g, '')
                removeToken()
                removeUserInfo() 
            })
        }
        return Promise.reject(new Error(res.message || 'Error'))
    },
    error => {
        console.log('response error', error.response)
        Message({
            message: error.response.data.message || error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)
export default service