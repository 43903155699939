/*
 * @Author: CHY
 * @Date: 2023-07-04 09:14:27
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-08-09 17:24:53
 * @FilePath: \food-trading\src\main.js
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAxios from "vue-axios"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' 
import service from '@/assets/js/utils/request.js'
import api from '@/assets/js/api/api'
import store from '@/store'

Vue.config.productionTip = false

Vue.use(router) 
Vue.use(ElementUI, {
    size: 'small',
    zIndex: 3000
})
Vue.use(VueAxios, service)

Vue.prototype.$api = api

new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App),
}).$mount('#app')