/*
 * @Author: CHY
 * @Date: 2023-07-05 17:18:06
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-08-08 16:38:05
 * @FilePath: \food-trading\src\assets\js\api\api.js
 */
// 命名格式：请求方式+具体名称，例如get请求的省份信息：getProvince
const api = {
    /**
     * 在线商城
     */
    getPurchaseType: '/api/rabbit-backendBidding/biddingPurchaseType/dict', // 获取商品类型
    getTypeDict: '/api/rabbit-liancan/biddingType/dict/', // 获取食材大类
    getSubtypeDict: '/api/rabbit-backendBidding/biddingSmallClass/dict/', // 获取食材小类
    getSupplierGoods: '/api/rabbit-liancan/supplierGoods/list', // 获取商品
    postSupplierGoodsCart: '/api/rabbit-liancan/SupplierGoodsCart/save', // 加入购物车
    getSupplierGoodsCart: '/api/rabbit-liancan/SupplierGoodsCart/list', // 获取购物车
    postRemoveSupplierGoodsCart: '/api/rabbit-liancan/SupplierGoodsCart/remove', // 删除购物车商品
    postSaveOrder: '/api/rabbit-liancan/order/save', // 直接下单
    postSaveOrderForConfirm: '/api/rabbit-liancan/SupplierGoodsCart/saveOrderForConfirm', // 提交确认
    postUpdateShoppingCart: '/api/rabbit-liancan/SupplierGoodsCart/update', // 更新购物车数据

    /**
     * 地区信息
     **/
    getProvince: '/api/rabbit-system/region/getProvince', // 获取省份
    getCity: '/api/rabbit-system/region/getCity/', // 获取市
    getArea: '/api/rabbit-system/region/getArea/', // 获取区

    /**
     * 通栏广告
     */
    getBanner: '/api/rabbit-foodTrade/horizontalBanner/list', // 获取通栏广告

    /**
     * 现场展示
     */
    getDistributionCenter: '/api/rabbit-foodTrade/distributionCenter/list', // 获取现场展示

    /**
     * 基地展示
     */
    getBaseDisplay: '/api/rabbit-foodTrade/baseDisplay/list', // 获取基地展示

    /**
     * 食品检测
     */
    getDetectionPublicity: '/api/rabbit-foodTrade/detectionPublicity/list', // 获取食品检测

    /**
     * 服务支持 、 联系我们
     */
    getFoodTrade: '/api/rabbit-foodTrade/foodTrade/list', // 获取服务支持 、 联系我们
    getLiancanNoticePage: '/api/rabbit-web2/notice/getLiancanNoticePage', // 获取服务中心
    getServerDetail: '/api/rabbit-liancan2/notice/serviceCenter/detail', // 获取服务中心Tab详情
    getLiancanNotice: '/api/rabbit-web2/notice/getLiancanNotice', // 获取服务中心详情

    /**
     * 实施案例
     */
    // getImplementationCases: '/api/rabbit-foodTrade/implementationCases/list', // 获取实施案例
    getImplementationCases: '/api/rabbit-foodTrade/implementationCases/listForTransform', // 获取实施案例

    /**
     * 其他
     */
    getInvoiceTitle: '/api/rabbit-liancan/invoiceTitle/list', // 获取纳税人识别号和地址

}

export default api