<template>
    <div class="back-top">
        <img src="images/icon_back_top.png" :class="show?'show':''" @click="toBackTop">
    </div>
</template>

<script>
    export default {
        data() {
            return {
                show: false
            }
        },
        mounted() {
            window.addEventListener("scroll", this.onPageScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onPageScroll)
        },
        methods: {
            onPageScroll() {
                let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
                this.show = scrollTop > 300
            },
            toBackTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>

<style lang="scss" scoped>

    .back-top {
        position: fixed;
        right: 0;
        bottom: 0;

        img {
            object-fit: contain;
            width: 60px;
            height: 60px;
            margin-bottom: 50px;
            margin-right: 50px;
            cursor: pointer;
            @include opacity(1, 0, "opacityOut", .2s);

            &.show {
                @include opacity(0, 1, "opacityIn", .2s);
            }
        }
    }
</style>