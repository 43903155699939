<!--
 * @Author: CHY
 * @Date: 2023-07-08 11:44:49
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-07-13 18:43:02
 * @FilePath: \food-trading\src\layout\Breadcrumb.vue
-->
<template>
    <div v-if="breadcrumb && names.length" class="breadcrumb-parent">
        <div class="breadcrumb">
            <img src="images/icon_home.png" class="home" />
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"> 首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, index) in names" :key="index" :to="{ path: item.path }">
                    <span :class="currentName===item.name?'active':''">{{ item.meta.title }}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
</template>

<script>
    import router from '@/router'

    export default {
        data() {
            return {
                breadcrumb: false,
                names: [],
                currentName: ''
            }
        },
        watch: {
            $route: {
                deep: true,
                immediate: true,
                handler(value) {
                    this.currentName = value.name
                    if (typeof value.meta.breadcrumb === 'boolean') {
                        this.breadcrumb = value.meta.breadcrumb
                    } else {
                        this.breadcrumb = false
                    }
                    this.names = this.findParentRoutes(router.options.routes, value.name).reverse()
                }
            }
        },
        methods: {
            findParentRoutes(routes, name, parentRoutes = []) {
                for (const route of routes) {
                    if (route.name === name) {
                        parentRoutes.push(route)
                        return parentRoutes
                    }

                    if (route.children && route.children.length > 0) {
                        const result = this.findParentRoutes(route.children, name, parentRoutes)
                        if (result.length > 0) {
                            parentRoutes.push(route)
                            return result
                        }
                    }
                }
                return []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .breadcrumb-parent {
        max-width: 1920px;
        margin: 0 auto;
        box-sizing: border-box;
        background: #FFF;
        border-bottom: 1px solid #F1F7F4FF;

        .breadcrumb {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            box-sizing: border-box;
            @include d-align-center;

            .home {
                width: 17px;
                height: 17px;
                margin-right: 10px;
            }

            ::v-deep .el-breadcrumb {
                font-size: 14px;
            }

            .active {
                color: rgba(0, 143, 76, 1);
            }

            ::v-deep .el-breadcrumb__inner.is-link:hover {
                color: rgba(0, 143, 76, 0.555);
            }
        }
    }
</style>